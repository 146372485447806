
import { Component, Vue } from 'vue-property-decorator'
import TheRegistration from '@/components/registration/TheRegistration.vue'
import ModalNote from '@/components/_modal/ModalNote.vue'
import TheRegistrationModal from '@/components/registration/TheRegistrationModal.vue'

@Component({
  components: {
    TheRegistration,
    TheRegistrationModal,
    ModalNote
  },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})

export default class RegistrationPage extends Vue {
  modalErrorText = ''
  showModalDone = false

  openModalError (text: string) {
    this.modalErrorText = text
  }

  openModalDone () {
    this.showModalDone = true
  }

  closeModal () {
    this.modalErrorText = ''
  }

}
