
import '@/assets/styles/imports/registration.sass'
import { Component, Vue } from 'vue-property-decorator'
import i18n from '@/i18n'
import api from '@/api'
import { DictionaryInterface } from '@/interfaces/dictionary'
import { RegisterParams } from '@/api/authorize'

@Component
export default class TheRegistration extends Vue {
  userFirstName = ''
  userFirstNameRequired = false
  userLastName = ''
  userLastNameRequired = false
  organizationName = ''
  currencyIdRequired = false
  currencyId = ''
  countryIdRequired = false
  countryId = ''
  email = ''
  emailRequired = false
  phone = ''
  password = ''
  passwordRequired = false
  passwordConfirm = ''
  passwordConfirmRequired = false
  passwordNotMatch = false
  agree = false
  currencies: Array<DictionaryInterface.Currency> = []
  countries: Array<DictionaryInterface.Country> = []
  languages: Array<DictionaryInterface.Language> = []

  fetchCurrencies () {
    api.dictionary.getCurrency().then(currencies => this.currencies = currencies)
  }

  fetchLanguage () {
    api.dictionary.getLanguage().then(languages => this.languages = languages)
  }

  fetchCountries () {
    api.dictionary.getCountry().then(countries => this.countries = countries)
  }

  async sendForm () {
    if (this.isNotCompletedField('userFirstName')) return
    if (this.isNotCompletedField('userLastName')) return
    if (this.isNotCompletedSelect('currencyId')) return
    if (this.isNotCompletedSelect('countryId')) return
    if (this.isNotCompletedField('email')) return
    if (this.isNotCompletedField('password')) return
    if (this.isNotCompletedField('passwordConfirm')) return
    if (this.comparisonPassword()) return
    const fields: RegisterParams = {
      userFirstName: this.userFirstName,
      userLastName: this.userLastName,
      organizationName: this.organizationName,
      languageId: this.getLanguageID(),
      currencyId: +this.currencyId,
      countryId: +this.countryId,
      email: this.email,
      phoneNumber: this.phone,
      password: this.password,
      passwordConfirm: this.passwordConfirm,
      isUserAgreed: this.agree
    }
    const { register, errors } = await api.authorize.register(fields)
    if (register) {
      await api.authorize.getUser()
      this.$emit('done')
      // return this.$router.push({ name: 'Home', params: { lang: this.$route.params.lang }})
    }
    if (errors?.length) this.openErrorModal(errors[0].error)
  }

  isNotCompletedField (name: string) {
    this.requiredField(name)
    return !this[name].length
  }

  requiredField (name: string) {
    this[`${name}Required`] = true
  }

  isNotCompletedSelect (name: string) {
    this.requiredField(name)
    return !this[name]
  }

  getLanguageID (): number {
    let lang = 'Russian'
    if (i18n.locale === 'en') lang = 'English'
    for (let i = 0, len = this.languages.length; i < len; i++) {
      if (this.languages[i].name === lang) return this.languages[i].id
    }
    return 1
  }

  comparisonPassword () {
    return this.passwordNotMatch = this.password !== this.passwordConfirm
  }

  openErrorModal (text: string) {
    this.$emit('error', text)
  }

  mounted () {
    this.fetchCurrencies()
    this.fetchCountries()
    this.fetchLanguage()
  }
}
