
import '@/assets/styles/imports/registration-modal.sass'
import { Component, Vue } from 'vue-property-decorator'
import storage from '@/storage'

@Component
export default class TheRegistrationModal extends Vue {
  email = ''

  close () {
    this.$router.push({ name: 'Home', params: { lang: this.$route.params.lang }})
  }

  mounted () {
    this.email = storage.user?.email || 'email'
  }
}
